.overlay {
  font-family: "Inter", sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 9999;
}

.overlay-content {
  background: white;
  border: 1px solid black;
  border-radius: 5px;
  padding: 20px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  position: relative;
}

.overlay-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  margin: auto;
  display: block;
}

.overlay-close-button {
  position: absolute;
  top: 7px;
  right: 7px;
  z-index: 100;
  border-radius: 50%;
  border: 0;
  justify-content: center;
  align-items: center;
  padding: 10px;

  font-size: 8px;
  font-weight: 600;

  background: radial-gradient(50% 50% at 50% 50%, #ff75f1 0%, #ffffff 100%);

  cursor: grab;
}

.overlay-content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.overlay-text {
  color: black;
  width: 30%;
  word-break: keep-all;
  padding: 0 10px;
  margin-right: 30px;
}

.overlay-text p {
  margin-top: 30px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5rem;
}

.overlay-image {
  width: 70%;
  padding: 10px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay-image img {
  padding-bottom: 10px;
}

.additional {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.additional img {
  max-width: 80%;
  max-height: 100%;
  object-fit: contain;
  margin: auto;
  display: block;
  margin-bottom: 10px;
}

.br-height {
  display: flex;
  height: 5px;
}

.overlay-title {
  font-size: 26px;
  margin-bottom: 0;
}
.overlay-year {
  font-size: 20px;
  font-weight: 500;
}

.overlay-tag {
  font-size: 0.8rem;
  font-weight: 600;
  padding: 5px 6px;
  margin-right: 6px;
  border-radius: 10px;
}

.yVideo {
  margin-bottom: 20px;
}

.overlay-close-button-btm {
  display: flex;
  align-items: center;
  font-size: 1em;
  padding: 0.5em 2em;
  margin: 0 auto;
  margin-top: 20px;
  -webkit-appearance: none;
  appearance: none;
  background-color: black;
  color: #ffffff;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  position: relative;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.5);
}

@media (max-width: 768px) {
  /* 768px 미만의 화면 크기에 대해 적용. 이 값은 필요에 따라 조절할 수 있습니다 */
  .overlay-content {
    max-width: 90%; /* 폭을 조정 */
    max-height: 90%; /* 높이를 조정 */
    flex-direction: column; /* 내용을 수직으로 배열 */
  }

  .overlay-content-wrapper {
    flex-direction: column; /* 내용을 수직으로 배열 */
  }

  .overlay-text,
  .overlay-image {
    width: 100%; /* 폭을 100%로 설정하여 전체 폭을 사용 */
    margin-right: 0; /* 오른쪽 여백 제거 */
    padding: 0;
  }

  .additional {
    width: 100%; /* 폭을 100%로 설정하여 전체 폭을 사용 */
    flex-direction: column; /* 항목을 수직으로 배열 */
  }

  .additional img {
    max-width: 100%; /* 이미지의 최대 폭을 100%로 설정 */
  }

  .yVideo {
    width: 100%; /* 영상의 폭을 100%로 설정 */
    height: auto; /* 영상의 높이를 자동으로 설정 */
    max-width: 100%; /* 영상의 최대 폭을 100%로 설정 */
    margin-bottom: 20px;
  }

  iframe.yVideo {
    width: 100%; /* iframe의 폭을 100%로 설정 */
    height: auto; /* iframe의 높이를 자동으로 설정 */
    aspect-ratio: 16/9; /* 16:9 비율을 유지 */
  }
}
