.about {
  position: fixed;
  top: 0;
  right: 0;
  width: 50px; /* 초기 너비 */
  height: 100%;
  background-color: white;
  color: white;
  transition: 0.5s; /* 호버 효과를 위한 전환 */
  text-align: center; /* 텍스트 정렬 */
  z-index: 999;
  box-shadow: 0px 0px 22.7px 0px rgba(0, 0, 0, 0.25);
}

.about:hover {
  width: 400px; /* 확장 너비 */
}

.main-content {
  transition: margin-right 0.5s;
}

.main-content.shrink {
  margin-right: 200px; /* 'About' 내비게이션이 확장될 때 메인 컨텐츠의 오른쪽 여백 */
}

.about-texts {
  font-size: 18px;
  opacity: 0;
  transition: opacity 0.5s ease 0.5s; /* 0.5초 지연 후 0.5초 동안 투명도 변경 */
  font-family: "Pretendard-Regular";
}

.about:hover .about-texts {
  opacity: 1;
  color: #f03c06;
}

.name {
  font-family: "Lexend";
  font-size: 32px;
  color: #f03c06;
}

.links {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.profile {
  width: 80%;
  display: block;
  margin: auto;
}

.links a {
  font-weight: 400;
  color: blue;
  text-decoration-line: none;
}

@media (max-width: 800px) {
  .about {
    display: none;
  }
}
