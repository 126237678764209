@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

.about-mo-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* 어두운 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.about-mo {
  width: 300px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 15px auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  z-index: 9999;
  background-color: white;
  box-shadow: 0px 0px 17.6px 0px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
}

.about-texts-mo {
  text-align: center;
  font-size: 15px;
  font-family: "Pretendard-Regular";
  font-weight: 500;
  line-height: 1.4rem;
  color: #f03c06;
  font-stretch: ultra-condensed;
}

.name-mo {
  font-family: "Lexend";
  font-size: 28px;
  width: 75%;
  line-height: 1em;
  margin: auto;
  color: #f03c06;
  border-top: 3px solid #f03c06;
  border-bottom: 3px solid #f03c06;
  padding: 14px;
}

.links-mo {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
}

.links-mo a {
  font-weight: 400;
  color: blue;
  padding: 10px;
  text-decoration-line: none;
  font-stretch: ultra-condensed;
}

.profile-mo {
  width: 80%;
  display: block;
  margin: auto;
}

.close-button {
  position: fixed;
}

@media (min-width: 801px) {
  .about-mo-container {
    display: none;
  }
  .about-mo {
    display: none;
  }
}
