@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@900&display=swap");

/* App.css 또는 index.css에 추가 */
@font-face {
  font-family: "BlackHanSans-Regular";
  src: url("./fonts/BlackHanSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-stretch: ultra-condensed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  padding: 20px;
}

.app-container {
  display: grid;
  grid-template-columns: 95% 5%;
  height: 100vh; /* 전체 높이를 차지하도록 설정 */
}

.masonry-grid-offset {
  margin-left: 50px; /* Assuming 'About' nav bar has an initial width of 50px */
}

.masonry-grid img {
  width: 100%;
  display: block;
  margin-bottom: 10px;
  object-fit: cover;
  cursor: grab;
}

.masonry-grid .image-caption {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
}

.masonry-grid img:hover + .image-caption {
  display: block;
}

.masonry-grid {
  display: flex;
  margin-left: -10px;
  width: auto;
}

.masonry-grid_column {
  padding-left: 10px;
  background-clip: padding-box;
}

.navbar-wrapper {
  position: relative;
  display: inline-block;
  width: 90%;
  user-select: none;
}

.navbar {
  margin-top: 10px;
  margin-left: 10px;

  width: 100%;
  position: relative;
  z-index: 1;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  padding: 5px 40px 5px 10px;
  border: 1px solid #ddd;
  border-radius: 1px;
  background-color: none;
  font-size: 16px;
  color: black;

  font-weight: 600;
}

.navbar-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateX(70%) translateY(-30%);
  z-index: 1;
  pointer-events: none;
}

/* App.css 또는 index.css에 추가 */

.about-container {
  grid-column: 2 / 3;
  transition: width 0.5s ease;
  width: 2%; /* 초기 너비 */
  overflow: hidden;
  /* 기타 스타일 */
}

.about-container.expanded {
  width: 20%; /* 확장된 너비 */
}

.hamburger-button {
  position: absolute;
  margin-top: 10px;
  top: 0;
  right: 0;
}

.fixed-button {
  position: fixed;
  right: 5px;
  bottom: 5px;
  z-index: 1000;

  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 0px;
  background-color: #f03c06;
  color: white;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.fixed-button {
  animation: rotate 3s ease-in-out infinite;
}

@media (max-width: 800px) {
  .app-container {
    grid-template-columns: none;
  }
  .navbar-wrapper {
    width: 95%;
  }
}

@media (min-width: 801px) {
  .fixed-button {
    display: none;
  }
}
